import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { t } from 'i18next';

import StatusCard from '../../common/StatusCard';
import {
  getApplicationList,
  getInvitationList,
  resetIsMerchantApplicationRejected,
  selectInviteDashboardDetails,
  setApplicationModalFlag,
  setIsApplicationSubmittedFlag,
  submitEntityMerchantApplication,
  submitMerchantApplication,
  updateMerchantInvitationStatus,
} from '../../../redux/feature/dashboard/invitationSlice';
import { MerchantApplicationType, ProcessType } from '../../../types/invitationTypes';
import { TCard, TLoader } from '../../myde-react-components';
import { APPLICATION_STATUS, MERCHANT_INVITE_STATUS } from '../../../constants/constants';
import { useRouter } from '../../../providers/custom-router-provider';
import * as ROUTES from '../../../constants/routes';
import { CustomIdProcessType } from '../../../types/inviteTypes';
import {
  selectEntity,
  setSelectedEntity,
  updateEntityApplicationStatus,
} from '../../../redux/feature/entity/entitySlice';
import { setAreAllOwnerIdRequirementsFulfilled } from '../../../redux/feature/idProcess/beneficialOwnerDocumentSlice';
import { EntityTypes } from '../../../types/entityTypes';

const ApproveInviteSubmit = () => {
  // Constants
  const dispatch = useDispatch();
  const { routeTo } = useRouter();

  // Redux Values
  const {
    selectedApplication,
    selectedProfiles,
    basicIdProcessList,
    customIdProcessList,
    isApplicationSubmitted,
    loading,
    isMerchantApplicationRejected,
  } = useSelector(selectInviteDashboardDetails);
  const { checkIsEntity, inviteCardSelectedEntity } = useSelector(selectEntity);

  // State Values
  const [applicationDetails, setApplicationDetails] = useState({} as MerchantApplicationType);
  const [selectedProfileList, setSelectedProfileList] = useState({} as any);
  const [idProcessList, setIdProcessList] = useState([] as ProcessType[]);
  const [customProcessList, setCustomProcessList] = useState([] as CustomIdProcessType[]);

  // Use Effects
  useEffect(() => {
    if (selectedApplication?.id) {
      setApplicationDetails(selectedApplication);
    }
  }, [selectedApplication]);
  useEffect(() => {
    setSelectedProfileList(selectedProfiles);
  }, [selectedProfiles]);
  useEffect(() => {
    setIdProcessList(basicIdProcessList);
  }, [basicIdProcessList]);
  useEffect(() => {
    setCustomProcessList(customIdProcessList);
  }, [customIdProcessList]);
  useEffect(() => {
    if (isApplicationSubmitted) {
      dispatch(setIsApplicationSubmittedFlag(false));
      routeTo(ROUTES.INVITATION_STATUS);
    }
  }, [isApplicationSubmitted]);

  // Methods
  const onSubmit = async () => {
    if (idProcessList?.length > 0 || customProcessList?.length > 0) {
      submitApplication();
      dispatch(setAreAllOwnerIdRequirementsFulfilled(false));
    } else {
      await submitInvitation();
      dispatch(setApplicationModalFlag(false));
      routeTo(ROUTES.INVITATION_STATUS);
    }
  };
  const submitApplication = async () => {
    const reqData = {
      applicationId: applicationDetails?.id,
      data: {
        action: APPLICATION_STATUS.SUBMIT,
        profiles: selectedProfileList,
      },
    };
    if (checkIsEntity) {
      await dispatch(submitEntityMerchantApplication(reqData));
    } else {
      await dispatch(submitMerchantApplication(reqData));
      if (isMerchantApplicationRejected) {
        routeTo(ROUTES.VIEW_INVITE);
        dispatch(resetIsMerchantApplicationRejected());
      }
    }
    await getUpdatedInvitationsAndApplications();
    dispatch(setSelectedEntity({} as EntityTypes));
  };
  const submitInvitation = () => {
    const reqData = {
      merchant_uid: applicationDetails?.merchant?.merchant_uid,
      action: APPLICATION_STATUS.START,
      invitation_id: applicationDetails?.id,
    };
    if (checkIsEntity) {
      const entityReqData = { ...reqData, entity_uid: inviteCardSelectedEntity?.trellis_uid };
      dispatch(updateEntityApplicationStatus(entityReqData));
    } else {
      dispatch(updateMerchantInvitationStatus(reqData));
    }
    getUpdatedInvitationsAndApplications();
    dispatch(setSelectedEntity({} as EntityTypes));
  };
  const getUpdatedInvitationsAndApplications = () => {
    dispatch(getInvitationList({ status: APPLICATION_STATUS.INVITED }));
    dispatch(
      getApplicationList({
        status_in: `{${MERCHANT_INVITE_STATUS.STARTED},${MERCHANT_INVITE_STATUS.UNDER_REVIEW},${MERCHANT_INVITE_STATUS.PENDING_REVIEW}}`,
      }),
    );
  };

  // HTML
  return (
    <Box sx={{ pt: 10 }}>
      <TLoader loading={loading} />
      <TCard sx={{ mt: 2 }}>
        <StatusCard
          logoUrl={applicationDetails?.merchant?.branding?.logo_url}
          title={t('allsetTitle', { ns: 'inviteFlow' })}
          body={
            <>
              <Box sx={{ pb: 3 }}>{t('allsetText1', { ns: 'inviteFlow' })}</Box>
              <Box sx={{ pb: 3 }}>
                {t('approvalConfirmationText', { ns: 'inviteFlow', merchantName: applicationDetails?.merchant?.name })}
              </Box>
              <Box sx={{ pb: 3 }}>{t('allsetText2', { ns: 'inviteFlow' })}</Box>
            </>
          }
          onClick={onSubmit}
          btnLable="I Approve"
        />
      </TCard>
    </Box>
  );
};

export default ApproveInviteSubmit;
